import type { ReactChild, ReactNode } from 'react';
import React from 'react';
import DOMPurify from 'dompurify';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';
import { cssMap } from '@compiled/react';

import { N40A, N50A, N30, N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { fg } from '@confluence/feature-gating';

import { SECTION_MESSAGE_APPEARANCE_TYPES } from '../constants';
import type { ConfluenceEdition } from '../Upgrade/__types__/waitForEditionChangeQuery';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div<{ isCard: boolean }>`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	max-width: ${({ isCard }) => (isCard ? '100%' : '95%')};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const SectionMessageTitle = styled.div`
	font: ${token('font.heading.small')};
	margin-top: 0;
	color: ${token('color.text')};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const SectionMessageBody = styled.div<{ withTitle: boolean }>`
	margin-top: ${({ withTitle }) => (withTitle ? `${token('space.100', '8px')}` : '0')};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const SectionMessageHeaderButton = styled.div<
	Pick<EditionSectionMessageProps, 'appearanceType'> & { isCard: boolean }
>`
	display: flex;
	justify-content: flex-end;
	position: absolute;
	top: ${({ appearanceType }) =>
		appearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.DEFAULT
			? `${token('space.400', '32px')}`
			: `${token('space.200', '16px')}`};
	right: ${({ isCard, appearanceType }) => {
		if (isCard && appearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.DEFAULT) {
			return `${token('space.400', '32px')}`;
		} else if (isCard) {
			return `${token('space.200', '16px')}`;
		} else if (appearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.DEFAULT) {
			return `${token('space.600', '48px')}`;
		} else {
			return `${token('space.300', '24px')}`;
		}
	}};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const SectionMessageActionRow = styled.div<{
	buttonGroupAlignment?: ButtonGroupAlignment;
}>`
	display: flex;
	margin-top: ${token('space.200', '16px')};

	${({ buttonGroupAlignment }) =>
		buttonGroupAlignment === 'right' &&
		`
    justify-content: flex-end;

    > * {
      flex-flow: row-reverse;
    }
  `}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- To migrate as part of go/ui-styling-standard
const SectionMessageFooter = styled.div`
	margin-top: ${token('space.200', '16px')};
	margin-bottom: ${token('space.200', '16px')};
	padding-top: ${token('space.150', '12px')};
	border-top: 2px solid ${token('color.border', N30)};
`;

// TODO(brizvash): non-convertable to compiled
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const SectionMessageContainer = styled.div<
	Pick<EditionSectionMessageProps, 'appearanceType' | 'withTasteAndToneStyling'>
>`
	background-color: ${({ withTasteAndToneStyling }) =>
		withTasteAndToneStyling ? null : `${token('elevation.surface.overlay', N0)}`};

	border-radius: 3px;
	display: flex;
	flex-grow: 1;

	margin: ${({ withTasteAndToneStyling }) => (withTasteAndToneStyling ? '0' : '0 auto')};

	${({ appearanceType }) => {
		switch (appearanceType) {
			case SECTION_MESSAGE_APPEARANCE_TYPES.DEFAULT:
				// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
				return css`
					padding: ${token('space.200', '16px')} ${token('space.300', '24px')};
					box-shadow: ${token(
						'elevation.shadow.overlay',
						`0 1px 1px ${N50A}, 0 0 1px 1px ${N40A}`,
					)};
					max-width: 710px;
				`;

			case SECTION_MESSAGE_APPEARANCE_TYPES.FLAT:
				// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
				return css`
					padding: ${token('space.200', '16px')} ${token('space.300', '24px')};
					max-width: 710px;
				`;

			case SECTION_MESSAGE_APPEARANCE_TYPES.INLINE:
				// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
				return css`
					font: ${token('font.body')};
				`;

			case SECTION_MESSAGE_APPEARANCE_TYPES.INLINE_MODAL:
				// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
				return css`
					max-width: 400px;
					${SectionMessageTitle} {
						font: ${token('font.heading.medium')};
						margin-top: ${token('space.0', '0')};
						padding-bottom: ${token('space.200', '16px')};
					}

					${SectionMessageActionRow} {
						margin-top: 96px;
						display: flex;
					}
				`;
		}
	}}
`;

const contentContainerStyles = cssMap({
	regular: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		maxWidth: '95%',
	},
	isCard: { maxWidth: '100%' },
});

const sectionMessageTitleStyles = cssMap({
	regular: {
		font: token('font.heading.small'),
		marginTop: 0,
		color: token('color.text'),
	},
	inlineModal: {
		font: token('font.heading.medium'),
		marginTop: token('space.0', '0'),
		paddingBottom: token('space.200', '16px'),
	},
});

const sectionMessageBodyStyles = cssMap({
	withTitle: { marginTop: token('space.100', '8px') },
	noTitle: { marginTop: '0' },
});

const sectionMessageHeaderButtonStyles = cssMap({
	regular: {
		display: 'flex',
		justifyContent: 'flex-end',
		position: 'absolute',
		top: token('space.200', '16px'),
		right: token('space.300', '24px'),
	},
	default: {
		top: token('space.400', '32px'),
		right: token('space.600', '48px'),
	},
	isCardDefault: {
		right: token('space.400', '32px'),
	},
	isCard: {
		right: token('space.200', '16px'),
	},
});

type ButtonGroupAlignment = 'left' | 'right';

const sectionMessageActionRowStyles = cssMap({
	default: {
		display: 'flex',
		marginTop: token('space.200', '16px'),
	},
	right: {
		justifyContent: 'flex-end',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'> *': {
			flexFlow: 'row-reverse',
		},
	},
	inlineModal: {
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
		marginTop: '96px',
		display: 'flex',
	},
});

const sectionMessageFooterStyles = cssMap({
	default: {
		marginTop: token('space.200', '16px'),
		marginBottom: token('space.200', '16px'),
		paddingTop: token('space.150', '12px'),
		borderTop: `2px solid ${token('color.border', N30)}`,
	},
});

const sectionMessageContainerStyles = cssMap({
	regular: {
		backgroundColor: token('elevation.surface.overlay', N0),
		borderRadius: '3px',
		display: 'flex',
		flexGrow: 1,
		margin: '0 auto',
	},
	withTasteAndToneStyling: {
		backgroundColor: 'transparent',
		margin: '0',
	},
	default: {
		padding: `${token('space.200', '16px')} ${token('space.300', '24px')}`,
		boxShadow: token('elevation.shadow.overlay', `0 1px 1px ${N50A}, 0 0 1px 1px ${N40A}`),
		maxWidth: '710px',
	},
	flat: {
		padding: `${token('space.200', '16px')} ${token('space.300', '24px')}`,
		maxWidth: '710px',
	},
	inline: {
		font: token('font.body'),
	},
	inlineModal: {
		maxWidth: '400px',
	},
});

export type EditionSectionMessageProps = {
	actions: ReactNode;
	appearanceType?: SECTION_MESSAGE_APPEARANCE_TYPES;
	body: ReactNode;
	edition?: ConfluenceEdition;
	footer?: ReactChild | null;
	title?: ReactChild;
	headerButton?: ReactChild;
	withTasteAndToneStyling?: boolean;
	buttonGroupAlignment?: ButtonGroupAlignment;
};

/**
 * @deprecated Please reach out to #cc-editions for details before use. Please read https://hello.atlassian.net/wiki/spaces/CE2/pages/2797298237/Contribution+Consumption+Guidelines+For+Common+Feature+Gate+Upsell+Component
 */
export const EditionSectionMessage = ({
	actions,
	appearanceType,
	body,
	footer,
	title,
	headerButton,
	withTasteAndToneStyling = false,
	buttonGroupAlignment = 'left',
}: EditionSectionMessageProps) => {
	const withTitle = Boolean(title);
	const isCard = Boolean(buttonGroupAlignment === 'right');

	const updatedAppearanceType =
		appearanceType ||
		(withTasteAndToneStyling
			? SECTION_MESSAGE_APPEARANCE_TYPES.INLINE
			: SECTION_MESSAGE_APPEARANCE_TYPES.DEFAULT);

	if (fg('confluence_frontend_change_edition_compiled')) {
		return (
			<div
				css={[
					sectionMessageContainerStyles['regular'],
					withTasteAndToneStyling && sectionMessageContainerStyles['withTasteAndToneStyling'],
					updatedAppearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.DEFAULT &&
						sectionMessageContainerStyles['default'],
					updatedAppearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.FLAT &&
						sectionMessageContainerStyles['flat'],
					updatedAppearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.INLINE &&
						sectionMessageContainerStyles['inline'],
					updatedAppearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.INLINE_MODAL &&
						sectionMessageContainerStyles['inlineModal'],
				]}
				data-testid="edition-section-message"
			>
				<div css={[contentContainerStyles['regular'], isCard && contentContainerStyles['isCard']]}>
					{withTitle && (
						<div
							css={[
								sectionMessageTitleStyles['regular'],
								updatedAppearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.INLINE_MODAL &&
									sectionMessageTitleStyles['inlineModal'],
							]}
						>
							{title}
						</div>
					)}
					{typeof body === 'string' ? (
						<div
							css={[
								sectionMessageBodyStyles['noTitle'],
								withTitle && sectionMessageBodyStyles['withTitle'],
							]}
							// See https://hello.atlassian.net/browse/APOLLO-3511
							// and other code tagged with this ticket
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }}
						/>
					) : (
						<div
							css={[
								sectionMessageBodyStyles['noTitle'],
								withTitle && sectionMessageBodyStyles['withTitle'],
							]}
						>
							{body}
						</div>
					)}
					{headerButton && (
						<div
							css={[
								sectionMessageHeaderButtonStyles['regular'],
								updatedAppearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.DEFAULT &&
									sectionMessageHeaderButtonStyles['default'],
								isCard && sectionMessageHeaderButtonStyles['isCard'],
								isCard &&
									updatedAppearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.DEFAULT &&
									sectionMessageHeaderButtonStyles['isCardDefault'],
							]}
						>
							{headerButton}
						</div>
					)}
					{actions && (
						<div
							css={[
								sectionMessageActionRowStyles['default'],
								buttonGroupAlignment === 'right' && sectionMessageActionRowStyles['right'],
								updatedAppearanceType === SECTION_MESSAGE_APPEARANCE_TYPES.INLINE_MODAL &&
									sectionMessageActionRowStyles['inlineModal'],
							]}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className="actionButtons"
						>
							{actions}
						</div>
					)}
					{footer && <div css={sectionMessageFooterStyles['default']}>{footer}</div>}
				</div>
			</div>
		);
	}

	return (
		<SectionMessageContainer
			appearanceType={updatedAppearanceType}
			data-testid="edition-section-message"
			withTasteAndToneStyling={withTasteAndToneStyling}
		>
			<ContentContainer isCard={isCard}>
				{withTitle && <SectionMessageTitle>{title}</SectionMessageTitle>}
				{typeof body === 'string' ? (
					<SectionMessageBody
						withTitle={withTitle}
						// See https://hello.atlassian.net/browse/APOLLO-3511
						// and other code tagged with this ticket
						dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }}
					/>
				) : (
					<SectionMessageBody withTitle={withTitle}>{body}</SectionMessageBody>
				)}
				{headerButton && (
					<SectionMessageHeaderButton isCard={isCard} appearanceType={updatedAppearanceType}>
						{headerButton}
					</SectionMessageHeaderButton>
				)}
				{actions && (
					<SectionMessageActionRow
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className="actionButtons"
						buttonGroupAlignment={buttonGroupAlignment}
					>
						{actions}
					</SectionMessageActionRow>
				)}
				{footer && <SectionMessageFooter>{footer}</SectionMessageFooter>}
			</ContentContainer>
		</SectionMessageContainer>
	);
};
